import { createRouter, createWebHashHistory } from "vue-router";


const routes = [

  {
    path: "/",
    name: "首页",
    component: () => import("../views/layout"),
    redirect: '/home',
    children: [
      {
        path: "/home",
        name: "首页",
        component: () =>
          import("../views/home/index"),
      },
      {
        path: "/user",
        name: "用户管理",
        component: () =>
          import("../views/user"),
      },
      {
        path: "/bigType",
        name: "中医院医生管理",
        component: () =>
          import("../views/bigType"),
      },
      {
        path: "/smallType",
        name: "乡镇医生管理",
        component: () =>
          import("../views/smallType"),
      },
      {
        path: "/product",
        name: "转诊单总表管理",
        component: () =>
          import("../views/product"),
      },
      {
        path: "/list_zyy_to_city",
        name: "中医院 -> 市级医院",
        component: () =>
          import("../views/list_zyy_to_city"),
      },
      {
        path: "/list_zyy_to_town",
        name: "中医院 -> 乡镇",
        component: () =>
          import("../views/list_zyy_to_town"),
      },
      {
        path: "/list_town_to_zyy",
        name: "乡镇 -> 中医院",
        component: () =>
          import("../views/list_town_to_zyy"),
      },
      {
        path: "/list_city_to_zyy",
        name: "市级医院 -> 中医院",
        component: () =>
          import("../views/list_city_to_zyy"),
      },
      {
        path: "/order",
        name: "咨询管理",
        component: () =>
          import("../views/order"),
      },
      {
        path: "/feedback3",
        name: "意见反馈管理",
        component: () =>
          import("../views/feedback3"),
      },
      {
        path: "/modifyPassword",
        name: "修改密码",
        component: () =>
          import("../views/modifyPassword"),
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/login"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
