import { createApp } from "vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// import ElementUI from 'element-ui'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '././assets/styles/border.css'
import '././assets/styles/reset.css'
import SvgIcon from '@/icons'
import '@/router/permission'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app=createApp(App);
SvgIcon(app);
app.component('QuillEditor', QuillEditor)
app.use(store).use(router).use(ElementPlus).mount("#app");


import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

app.use(ElementPlus, {
  locale: zhCn,
})


import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
